import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { MAIN_COLORS } from "./utils";
import LandingPage from "./pages/LandingPage";
import "./App.css";

function App() {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: MAIN_COLORS.BACKGROUND,
      }}
    >
      <Router>
        <Routes>
          <Route path="/" exact element={<LandingPage />} />
        </Routes>
      </Router>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
