import GPButton from "./GPButton";
import IOSButton from "./IOSButton";
const Buttons = () => {
  return (
    <div>
      <GPButton style={{ width: 350, padding: 20, borderRadius: 40 }} />
      <IOSButton style={{ width: 350, padding: 20, borderRadius: 40 }} />
    </div>
  );
};

export default Buttons;
