import logo from "../assets/LOGO.png";
import "../App.css";
import Buttons from "../components/Buttons";
import { Image } from "react-bootstrap";
function LandingPage() {
  return (
    <div
      style={{
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Image src={logo} style={{ marginTop: 50, width: 250 }} />
      <p
        style={{
          color: "white",
          textAlign: "center",
          fontSize: 20,
          padding: 30,
        }}
      >
        Con Explica, escanea y resuelve problemas al instante con Inteligencia
        Artificial
      </p>
      <Buttons />
    </div>
  );
}

export default LandingPage;
