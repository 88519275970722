const MAIN_COLORS = {
  FIRST: "#5D3FD3",
  SECOND: "#BF40BF",
  BOY: "#4169e1",
  GIRL: "#FF69B4",
  BACKGROUND: "#1F2022",
  FOREGROUND: "#ededed",
  WHATSAPP: "#25D366",
};
const isNonemptyObject = (obj) => {
  return (
    obj && // 👈 null and undefined check
    Object.getPrototypeOf(obj) === Object.prototype &&
    Object.keys(obj).length !== 0
  );
};

const schoolsAvailable = {
  markham: "Markham College",
};

export { MAIN_COLORS, isNonemptyObject, schoolsAvailable };
