import gp from "../assets/GP.jpeg";
import { Image } from "react-bootstrap";
const androidURL =
  "https://play.google.com/store/apps/details?id=com.xplainai.explica";

const GPButton = ({ style }) => {
  return (
    <a href={androidURL} target="_blank">
      <Image src={gp} alt="play store" style={style} />
    </a>
  );
};

export default GPButton;
