import as from "../assets/IOS.jpeg";

import { Image } from "react-bootstrap";
const iOSURL = "https://apps.apple.com/ca/app/explica/id6449973300";

const IOSButton = ({ style }) => {
  return (
    <a href={iOSURL} target="_blank">
      <Image src={as} alt="app store" style={style} />
    </a>
  );
};

export default IOSButton;
